import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { BaseApiService } from './base-api.service';
import { catchError, map } from 'rxjs/operators';
import { AttendeeModel } from '../models/attendee.model';
import { NotificationService } from './notification.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { access } from 'fs';

@Injectable({
  providedIn: 'root'
})
export class CheckInService {

  isCheckingIn = new BehaviorSubject<boolean>(false);
  isCheckingOut = new BehaviorSubject<boolean>(false);
  private ATTENDEE = new BehaviorSubject<AttendeeModel>(new AttendeeModel());
  attendee = this.ATTENDEE.asObservable();

  constructor(
    private api: BaseApiService,
    private router: Router,
    private notifyService: NotificationService,
    private translate: TranslateService,
  ) {

  }

  redirectToSuccess(attendee: any, type: string = 'check-in') {
    let now = new Date();
    let route = this.router.config.find(r => r.path === 'success/:timestamp');

    if (route) {
      route.data = { attendee: attendee, type: type };
      this.router.navigate(['/success', now.getTime()]);
    }
  }

  redirectToError(attendee: any, type: string = 'check-in') {
    let route = this.router.config.find(r => r.path === 'error');

    if (route) {
      route.data = { attendee: attendee, type: type };
      this.router.navigateByUrl('/error');
    }
  }

  checkAuthCode(authCode: any): Promise<AttendeeModel> {
    return new Promise((resolve, reject) => {
      this.getAttendeeByAuthCode(authCode).subscribe(
        (attendee) => this.updateAttendeeStatus(authCode, attendee).subscribe(
          (attendee) => resolve(attendee),
          (ex) => reject(ex)),
        (ex) => reject(ex)
      )
    });
  }

  getAttendeeByAuthCode(authCode: any, params: any = {}): Observable<AttendeeModel> {
    return this.api.get(`/check-in/${authCode}`, params).pipe(
      map(response => new AttendeeModel(response.body)),
      catchError(this.handleError<AttendeeModel>(`getAttendeeByAuthCode authCode = ${authCode};`))
    );
  }

  updateAttendeeByAuthCode(authCode: any, attendee: AttendeeModel, params: any = {}): Observable<AttendeeModel> {
    return this.api.put(`/check-in/${authCode}`, attendee.toApi(), params).pipe(
      map(response => new AttendeeModel(response.body)),
      catchError(this.handleError<AttendeeModel>(`updateAttendeeByAuthCode authCode = ${authCode};`))
    );
  }

  getAttendeeAccessCardByAuthCode(authCode: any, params: any = {}): Observable<AttendeeModel> {
    return this.api.get(`/check-in/${authCode}/access-card`, params).pipe(
      map(response => new AttendeeModel(response.body)),
      catchError(this.handleError<AttendeeModel>(`getAttendeeAccessCardByAuthCode authCode = ${authCode};`))
    );
  }

  createAttendeeAccessCardByAuthCode(authCode: any, accessCardNumber: string, params: any = {}): Observable<AttendeeModel> {
    return this.api.post(`/check-in/${authCode}/access-card`, { access_card_number: accessCardNumber }, params).pipe(
      map(response => new AttendeeModel(response.body)),
      catchError(this.handleError<AttendeeModel>(`createAttendeeAccessCardByAuthCode authCode = ${authCode};`))
    );
  }

  deleteAttendeeAccessCardByAuthCode(authCode: any, params: any = {}): Observable<AttendeeModel> {
    return this.api.delete(`/check-in/${authCode}/access-card`, params).pipe(
      map(response => new AttendeeModel(response.body)),
      catchError(this.handleError<AttendeeModel>(`updateAttendeeAccessCardByAuthCode authCode = ${authCode};`))
    );
  }

  deleteAttendeeAccessCardByCardNumber(accessCardNumber: any, params: any = {}): Observable<AttendeeModel> {
    return this.api.delete(`/check-in/access-card/${accessCardNumber}`, params).pipe(
      map(response => new AttendeeModel(response.body)),
      catchError(this.handleError<AttendeeModel>(`deleteAttendeeAccessCardByCardNumber accessCardNumber = ${accessCardNumber};`))
    );
  }

  createAccessCardError(payload: any, params: any = {}): Observable<any> {
    return this.api.post(`/check-in/access-card/error`, payload, params).pipe(
      map(response => response.body),
      catchError(this.handleError<AttendeeModel>(`createAccessCardError;`))
    );
  }

  private updateAttendeeStatus(authCode: any, attendee: AttendeeModel) {
    attendee.status = AttendeeModel.STATUS_CHECKED_IN;

    return this.updateAttendeeByAuthCode(authCode, attendee, { expand: "appointment.company,appointment.hosts" });
  }

  displayError(code: any) {
    let errorMessage = '';

    switch (code) {
      case 403: errorMessage = this.translate.instant('Your appointment has expired.'); break;
      case 404: errorMessage = this.translate.instant('Invalid invitation code.'); break;
      case 405: errorMessage = this.translate.instant('Invalid registration code scanned.'); break;
      case 406: errorMessage = this.translate.instant('Your appointment is canceled.'); break;
      case 407: errorMessage = this.translate.instant('Host removed you from meeting.'); break;
      case 408: errorMessage = this.translate.instant('You already checked in.'); break;
      case 409: errorMessage = this.translate.instant('Access card already issued.'); break;
      case 410: errorMessage = this.translate.instant('You are attempting to check in too early. Please return closer to the scheduled time.'); break;
      case 444: errorMessage = this.translate.instant('Something wrong with the card dispenser, please try again later'); break;
      default: errorMessage = this.translate.instant('Invalid code.');
    }

    this.notifyService.showError(errorMessage);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      const errorMsg = `${operation} failed: ${error.message}`;
      console.log(errorMsg);

      return throwError(error);
    };
  }


}
