import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { BaseApiService } from './base-api.service';
import { catchError, map } from 'rxjs/operators';
import { Call, Device } from '@twilio/voice-sdk';
import { CompanyModel } from '../models/company.model';
import { CompanyService } from './company.service';

@Injectable({
  providedIn: 'root'
})
export class TwilioService {

  static STATUS_FAILED = 'failed'; //The call could not be completed as dialed, most likely because the phone number was non-existent.
  static STATUS_BUSY = 'busy'; //The caller received a busy signal.
  static STATUS_IN_PROGRESS = 'in-progress'; //The call was answered and is actively in progress.
  static STATUS_COMPLETED = 'completed'; //The call was answered and has ended normally.
  static STATUS_NO_ANSWER = 'no-answer'; //The call ended without being answered.
  static STATUS_INITIALIZING = 'initializing';
  static STATUS_CONNECTING = 'connecting';
  static STATUS_DISCONNECTED = 'disconnected';
  static STATUS_REJECTED = 'rejected';
  static STATUS_ERROR = 'error';
  static STATUS_RINGING = 'ringing';
  static STATUS_ACTIVE = 'active';

  static TYPE_CONTACT = 'contact';
  static TYPE_COMPANY = 'company';

  private call?: Call;
  private device?: Device;
  private callees?: CompanyModel[] = [];

  callStatus = new Subject<string>();

  constructor(
    private api: BaseApiService,
    private companyService: CompanyService
  ) {

  }

  initializeCallees() {
    const params = {
      is_removed: 0,
      is_on_display: 1,
      'per-page': 100,
      sort: 'created_at',
      expand: 'image,contacts.image'
    };
    return this.companyService.getCompanies(params).subscribe((companies: CompanyModel[]) => {
      this.callees = companies;
    });
  }

  getTenantList() {
    return this.callees?.filter((company: CompanyModel) => company.receptionDisplay == CompanyModel.TYPE_TENANT) || [];
  }

  getFacilityManagerList() {
    return this.callees?.filter((company: CompanyModel) => company.receptionDisplay == CompanyModel.TYPE_FM_PROVIDER) || [];
  }

  initCall(callerId: string | undefined, type: string = TwilioService.TYPE_COMPANY) {
    this.setCallStatus(TwilioService.STATUS_INITIALIZING);
    this.getCallToken().subscribe(token => {
      this.device = new Device(token);
      this.device?.on('error', (err) => {
        console.log('DEVICE ERROR:', err);
        this.setCallStatus(TwilioService.STATUS_ERROR);
      });

      this.initConnection(callerId, type);
    })
  }

  destroyCall() {
    this.call?.disconnect();
    this.call = undefined;
  }

  destroyDevice() {
    try {
      this.device?.disconnectAll();
      this.device?.destroy();
    } catch (ex) {
      console.log('Unable to destroy device - ', ex);
    }
  }

  private initConnection(callerId: string | undefined, type: string = TwilioService.TYPE_COMPANY) {
    let params = {
      params: {
        To: callerId || '',
        Type: type || '',
      }
    };

    this.device?.connect(params).then((call: Call) => {
      this.call = call;
      this.setCallStatus(TwilioService.STATUS_CONNECTING);
      this.setCallEvents();
    }, (err: any) => {
      console.log('CONNECTION ERROR', err);
      this.setCallStatus(TwilioService.STATUS_ERROR);
    });
  }

  private setCallStatus(status: string) {
    this.callStatus.next(status);
  }

  private setCallEvents() {
    this.call?.on('ringing', call => {
      this.setCallStatus(TwilioService.STATUS_RINGING);
    });

    this.call?.on('accept', call => {
      this.setCallStatus(TwilioService.STATUS_ACTIVE);
    });

    this.call?.on('disconnect', call => {
      this.setCallStatus(TwilioService.STATUS_DISCONNECTED);
    });

    this.call?.on('error', error => {
      console.log('An error has occurred: ', error);
      this.setCallStatus(TwilioService.STATUS_ERROR);
    });
  }

  getCallToken(params: any = {}): Observable<string> { //TODO make private
    return this.api.get(`/twilio/token`, params).pipe(
      map(response => response.body?.token),
      catchError(this.handleError<string>(`getCallToken`))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      const errorMsg = `${operation} failed: ${error.message}`;
      console.log(errorMsg);

      return throwError(error);
    };
  }

}
