import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class BaseApiService {

  protected constructor(
    private http: HttpClient,
  ) {
  }

  public get(path: string = '', queryParams: any = {}): Observable<HttpResponse<any>> {
    return this.http.get(`${environment.apiUrl}${path}`, {
      headers: this.headers,
      observe: 'response',
      params: queryParams
    });
  }

  public post(path: string, body: any, queryParams: any = {}, options = {}): Observable<HttpResponse<any>> {
    return this.http.post(`${environment.apiUrl}${path}`, body, {
      headers: this.headers,
      observe: 'response',
      params: queryParams,
      ...options
    });
  }

  public put(path: string = '', body: any, queryParams: any = {}): Observable<HttpResponse<any>> {
    return this.http.put(`${environment.apiUrl}${path}`, body, {
      headers: this.headers,
      observe: 'response',
      params: queryParams
    });
  }

  public delete(path: string = '', queryParams: any = {}): Observable<HttpResponse<any>> {
    return this.http.delete(`${environment.apiUrl}${path}`, {
      headers: this.headers,
      observe: 'response',
      params: queryParams
    });
  }

  public get headers(): HttpHeaders {
    let headers: any = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Accept-Language': environment.defaultAppLanguage
    };

    if (environment.instanceReception?.key) {
      headers['Digital-Reception'] = environment.instanceReception?.key;
    }

    return new HttpHeaders(headers);
  }

}
