import { AttendeeModel } from './attendee.model';
import { BaseModel } from './base.model';
import { CompanyModel } from './company.model';

export class AppointmentModel extends BaseModel {
  id?: string;
  title?: string;
  description?: string;
  status?: string;
  startDateTime?: string;
  finishDateTime?: string;
  company?: CompanyModel;
  hosts!: AttendeeModel[];
  visitors?: AttendeeModel[];

  get attributeMap(): { [key: string]: any } {
    return {
      auth_hash: "id",
      start_time: "startDateTime",
      end_time: "finishDateTime",
    };
  }

  get childModels(): { [key: string]: any } {
    return {
      company: CompanyModel,
      hosts: AttendeeModel,
      visitors: AttendeeModel,
    };
  }

  getHostsString(): string {
    return this.hosts?.map(attendee => {
      return attendee.fullName;
    }).join(', ');
  }
}


