import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CompanyModel } from '../models/company.model';
import { BaseApiService } from './base-api.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(
    private api: BaseApiService
  ) { }

  getCompanies(params = {}): Observable<CompanyModel[]> {
    return this.api.get('/companies', params).pipe(
      map(this.transformResponseModelArray()),
      catchError(this.handleError<CompanyModel[]>('getCompanies', []))
    );
  }

  private transformResponseModelArray() {
    return (res: any): CompanyModel[] => {
      if (res.body) {
        return res.body.map((company: any) => new CompanyModel(company));
      }

      return [];
    };
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(`${operation} failed: ${error.message}`);

      return of(result as T);
    };
  }
}
