import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import { CarouselComponent } from './carousel/carousel.component';
import {RouterModule} from "@angular/router";



@NgModule({
  declarations: [CarouselComponent],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule
  ],
  exports: [
    TranslateModule,
    CarouselComponent,
    CommonModule,
  ]
})
export class SharedModule { }
