import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  constructor(
    private toastr: ToastrService
  ) { }

  showSuccess(message: string, title: string = '') {
    this.toastr.success(message, title, { toastClass: 'bg-success text-light', timeOut: 5000 })
  }

  showError(message: string, title: string = '') {
    this.toastr.error(message, title, { toastClass: 'bg-danger text-light', timeOut: 5000 })
  }

  showInfo(message: string, title: string = '') {
    this.toastr.info(message, title, { toastClass: 'bg-info text-light', timeOut: 5000 })
  }

  showWarning(message: string, title: string = '') {
    this.toastr.warning(message, title, { toastClass: 'bg-warning text-light', timeOut: 5000 })
  }
}
