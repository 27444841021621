import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  constructor(
    // private apiService: BaseApiService
    private http: HttpClient
  ) {

  }

  logMessage(message: any) {
    // return this.apiService.post('/test/log', message);
    return this.http.post(`${environment.apiUrl}/test/log`, message);
  }
}
