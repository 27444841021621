import { environment } from '../../environments/environment';
import { AppointmentModel } from './appointment.model';
import { BaseModel } from './base.model';

export class AttendeeModel extends BaseModel {

  static STATUS_INVITED = 'invited';
  static STATUS_WAITING = 'waiting';
  static STATUS_CHECKED_IN = 'checked-in';

  static CARD_STATUS_ISSUED = 'issued';
  static CARD_STATUS_RETURNED = 'returned';

  id?: number;
  personalTitle!: string;
  firstName!: string;
  lastName!: string;
  email?: string;
  status?: string;
  startDateTime?: string;
  finishDateTime?: string;
  accessCardNumber?: string;
  accessCardStatus?: string;
  appointment?: AppointmentModel;

  get attributeMap(): { [key: string]: any } {
    return {
      mail: "email",
      name: "firstName",
      surname: "lastName",
    };
  }

  get childModels(): { [key: string]: any } {
    return {
      appointment: AppointmentModel,
    };
  }

  get fullName(): string | undefined {
    return ((this.personalTitle || '') + ' ' + (this.firstName || '') + ' ' + (this.lastName || '')).trim() || this.email;
  }

  get isAccessCardIssued(): boolean {
    return !environment.cardDispenserExtensionId || (!!this.accessCardNumber && this.accessCardStatus == AttendeeModel.CARD_STATUS_ISSUED);
  }

}

