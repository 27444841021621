import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/welcome/welcome.module').then((m) => m.WelcomeModule),
  },
  {
    path: 'scan',
    loadChildren: () => import('./pages/scan-qr/scan-qr.module').then((m) => m.ScanQrModule),
  },
  {
    path: 'code',
    loadChildren: () => import('./pages/insert-code/insert-code.module').then((m) => m.InsertCodeModule),
  },
  {
    path: 'call',
    loadChildren: () => import('./pages/call-tenant/call-tenant.module').then((m) => m.CallTenantModule),
  },
  {
    path: 'success/:timestamp',
    loadChildren: () => import('./pages/success/success.module').then((m) => m.SuccessModule),
  },
  {
    path: 'error',
    loadChildren: () => import('./pages/error/error.module').then((m) => m.ErrorModule),
  },
  {
    path: 'help',
    loadChildren: () => import('./pages/help/help.module').then((m) => m.HelpModule),
  },
  {
    path: 'maintenance',
    loadChildren: () => import('./pages/maintenance/maintenance.module').then((m) => m.MaintenanceModule),
  },
  { path: '**', redirectTo: 'error' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
